
.App-logo {
  height: 60px;
  pointer-events: none;
}

.Home-Image {
  width: 31%;
  pointer-events: none;
  padding: 1%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


/* src/App.css */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}

.App {
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  /*padding-left: 10%;
  padding-right: 10%;*/
}

.header {
  display: flex;
  flex-direction: column; /* Change to column on small screens */
  justify-content: space-between;
  align-items: center;
  background-color: #660033;
  margin-top: 20px;
  padding: 10px;
  flex: 0 0 auto; /* Don't allow the header to grow or shrink */
}

.logo {
  font-size: 24px;
  font-weight: bold;
}


.menu ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap; /* Wrap the menu items on small screens */
  justify-content: center; /* Center the menu items horizontally on small screens */
}

.menu li {
  margin-right: 20px;
  cursor: pointer;
}

.content {
  padding: 20px;
  flex: 1 0 auto; /* Allow the content to grow, but don't allow it to shrink */
}

.footer {
  background-color: #660033;
  padding: 10px;
  margin-bottom: 20px;
  flex: 0 0 auto; /* Don't allow the footer to grow or shrink */
}

.Solutions {
  width: 100%;
}

.Solution {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.Solution-Image {
  width: 80%;
  pointer-events: none;
  padding: 1%;
}


.image-container {
  flex: 1 0 300px;
  padding: 10px;
}

.description-container {
  flex: 1 0 300px;
  padding: 10px;
}

.Customer-Logo {
  height: 60px;
  pointer-events: none;
  padding: 5px;
}

.Customer-Logo-White {
  height: 60px;
  pointer-events: none;
  padding: 5px;
  background-color: white;
}
.Customer-Logo-Link {
  display: inline-block; /* Display as block to cover the image */
  text-decoration: none; /* Remove default underline styling */
}

/* Add a media query to adjust styles on small screens */
@media screen and (min-width: 768px) {
  .header {
    flex-direction: row; /* Reset to row on screens larger than 768px */
    justify-content: space-between; /* Align logo and menu at opposite ends */
  }

  .menu {
    margin: 0; /* Reset margin on larger screens */
  }

  .menu ul {
    flex-wrap: nowrap; /* Reset to nowrap on larger screens */
  }
}